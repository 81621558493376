import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="all">
      <div className="FooterAll">
      <div class="hr"><hr /></div>
        <p className="p2">@this is a web page on 2/2023 </p>
      </div>
    </div>
  );
}

export default Footer;
